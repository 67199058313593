@use "./colors.scss" as *;
@use "./fonts.scss" as *;
@import-normalize;

html, body, #root {
  width: 100vw;
  height: 100vh;
}

* {
  box-sizing: border-box;
  color-scheme: dark;
  
  scroll-behavior: smooth;

  padding: 0;
  margin: 0;

  font-family: var(--font-inter);
}

a, button {
  cursor: pointer;
}

button:focus {
  outline:0;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: var(--color-gray-5);
}

#root {
  overflow-x: auto;
}