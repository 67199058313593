:root {
	/* --color-primary: #623CEA;
	--color-primary-50: #311E75; */
	--color-primary: #e6b820;
	--color-primary-50: #75591e;
	--color-secondary: #50CF9A;
	--color-secondary-rgb: 80, 207, 154;
	--color-secondary-50: #11774d;
	--color-tertiary: #FF577F;
	--color-tertiary-rgb: 255, 87, 127;
	--color-tertiary-50: #FF8BA6;
	--color-quaternary: #506CCF;
	--color-quaternary-50: #E5E9F8;
	
	--color-facebook: #506CCF;
	--color-instagram-rgb: 207, 80, 172;
	--color-linkedin-rgb: 14, 118, 168;
	
	--color-black-fixed: #000000;
	--color-kinda-black: #0A0A0B;
	--color-gray-5: #121214;
	--color-gray-4: #212529;
	--color-gray-3: #868E96;
	--color-gray-2: #dfdfdf;
	--color-gray-1: #F8F9FA;
	--color-white-fixed: #FFFFFF;
}